import React from "react";
import { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import Overview from "./pages/Overview/Overview";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy/CancellationRefundPolicy";

const WebDevelopment = lazy(() =>
  import("./pages/Services/WebDevelopment/WebDevelopment")
);
const MobileAppDevelopment = lazy(() =>
  import("./pages/Services/MobileAppDevelopment/MobileAppDevelopment")
);
const SoftwareDevelopment = lazy(() =>
  import("./pages/Services/SoftwareDevelopment/SoftwareDevelopment")
);
const DigitalMarketing = lazy(() =>
  import("./pages/Services/DigitalMarketing/DigitalMarketing")
);
const SearchEngineOptimization = lazy(() =>
  import("./pages/Services/SearchEngineOptimization/SearchEngineOptimization")
);

const Router = () => {
  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route
              path="web-development-and-designing"
              element={<WebDevelopment />}
            />
            <Route
              path="mobile-app-development"
              element={<MobileAppDevelopment />}
            />
            <Route
              path="software-development"
              element={<SoftwareDevelopment />}
            />
            <Route path="digital-marketing" element={<DigitalMarketing />} />
            <Route
              path="search-engine-optimization"
              element={<SearchEngineOptimization />}
            />
          </Route>
          <Route path="/overview" element={<Overview />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsCondition />}
          ></Route>
          <Route
            path="/cancellation-and-refund-policy"
            element={<CancellationRefundPolicy />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
