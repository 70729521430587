import React, { useState } from "react";
import "./style.scss";
import { submitForm } from "../../shared/services/contactus.service";

const ContactForm = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contactForm, setContactForm] = useState({});

  const onToggleForm = () => {
    setIsOpen(!isOpen);
  };

  React.useImperativeHandle(ref, () => ({
    onToggleForm,
  }));

  const onContactFormInputHandler = (event) => {
    const { name, value } = event.target;

    setContactForm((props) => ({
      ...props,
      [name]: value,
    }));
  };

  const onContactFormSubmit = (e) => {
    e.preventDefault();

    submitForm(contactForm)
      .then((response) => {
        setContactForm({});

        // Trigger the Google Analytics conversion event
        window.gtag("event", "conversion", {
          send_to: "AW-11328194697/bCAlCOzym-AYEImR2pkq",
          value: 1.0,
          currency: "INR",
        });
      })
      .catch((error) => {});
  };

  return (
    <div className="contact-form-block">
      <button className="fab-button" onClick={onToggleForm}>
        <i className="fa-solid fa-rocket"></i>
      </button>

      <div className={`contact-form ${isOpen ? "open" : ""}`}>
        <h2>Contact Us</h2>
        <form action="#" method="post" onSubmit={onContactFormSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={contactForm.name || ""}
            onChange={onContactFormInputHandler}
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={contactForm.email || ""}
            onChange={onContactFormInputHandler}
          />

          <label htmlFor="contact_number">Contact Number</label>
          <input
            type="number"
            id="contact_number"
            name="contact_number"
            required
            value={contactForm.contact_number || ""}
            onChange={onContactFormInputHandler}
          />

          <label htmlFor="services">Services</label>
          <select
            id="services"
            name="service"
            value={contactForm.service || ""}
            onChange={onContactFormInputHandler}
          >
            <option value="web-development">Web Development</option>
            <option value="mobile-app-development">
              Mobile App Development
            </option>
            <option value="software-development">Software Development</option>
            <option value="digital-marketing">Digital Marketing</option>
            <option value="seo">Search Engine Optimization</option>
          </select>

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            required
            value={contactForm.message || ""}
            onChange={onContactFormInputHandler}
          ></textarea>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
});

export default ContactForm;
