import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal);

const alert = {
  success: (title) => {
    swal.fire({
      customClass: "app-alert success",
      html: (
        <>
          <img
            style={{ width: "60%" }}
            src="/media/images/success-alert.gif"
            alt="Success Alert"
            srcSet=""
          />
        </>
      ),
    });
  },
};

export default alert;
