import React from "react";
import "./style.scss";

const CancellationRefundPolicy = () => {
  return (
    <>
      <div id="policy-page">
        <h1>Cancellation / Refund Policy</h1>
        <ul>
          <li>
            <strong>Cancellation by the customer:</strong> If a customer wishes
            to cancel an order, they must inform us in writing (email or letter)
            within 24 hours of placing the order. A full refund will be given
            for orders that have not been shipped. For orders that have been
            shipped, a refund will be given minus the cost of shipping and
            handling.
          </li>
          <li>
            <strong>Cancellation by the company:</strong> We reserve the right
            to cancel an order at any time and for any reason. In the event of
            cancellation by us, a full refund will be given to the customer.
          </li>
          <li>
            <strong>Refunds:</strong> Refunds will be processed within 7-10
            business days of receiving the request for refund. The refund will
            be made to the same method of payment used for the original
            transaction.
          </li>
          <li>
            <strong>Damaged or defective products:</strong> If a customer
            receives a damaged or defective product, we must be informed within
            24 hours of delivery. We will arrange for the return of the damaged
            or defective product and provide a full refund or replacement.
          </li>
          <li>
            <strong>Return policy:</strong> All products sold on our website are
            final sale and cannot be returned or exchanged.
          </li>
          <li>
            <strong>Dispute Resolution:</strong> Any disputes arising out of or
            related to this refund policy shall be resolved through friendly
            negotiation. If negotiation fails, the dispute shall be resolved by
            mediation in accordance with the Indian Mediation and Conciliation
            Rules.
          </li>
        </ul>
      </div>
    </>
  );
};

export default CancellationRefundPolicy;
