import React, { useState } from "react";
import "./style.scss";
import "./style.responsive.scss";
import { useLocation, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const { isPageOnTop, onNavigateToSection, onToggleMobileMenu } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const navigateToSection = (section) => {
    if (location.pathname === "/") {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(`/?section=${section}`);
    }
  };

  return (
    <>
      <nav className={isPageOnTop ? "navbar" : "navbar active"}>
        <img className="logo" src="/media/images/company-logo.png" alt="Company Logo" />
        <ul className="menu">
          <li
            onClick={() => {
              navigateToSection("home");
            }}
          >
            Home
          </li>
          <li
            onClick={() => {
              navigateToSection("about-us");
            }}
          >
            About Us
          </li>
          <li
            onClick={() => {
              navigateToSection("services");
            }}
          >
            Services
          </li>
          <li
            onClick={() => {
              navigateToSection("contact-us");
            }}
          >
            Contact
          </li>
        </ul>
        <div onClick={onToggleMobileMenu} className="mobile-menu">
          <i className="icon fas fa-bars"></i>
        </div>
      </nav>
    </>
  );
}
