import React from "react";
import "./style.scss";
import "./style.responsive.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const onVisitLink = (link) => {
    window.open(link);
  };

  return (
    <section id="footer">
      <div className="contact-block">
        <div
          className="item"
          onClick={() => {
            onVisitLink("https://goo.gl/maps/uWqk1nwspgnzbQJK9");
          }}
        >
          <i className="icon fas fa-location-dot"></i>
          <br />
          <label className="value">
            HD-134 WeWork, <br /> Magarpatta, Hadapsar, <br /> Pune, Maharashtra
            411028
          </label>
          <br />
          <label className="key">Our Address</label>
        </div>
        <div
          className="item"
          onClick={() => {
            onVisitLink("mailto:info@alwaantechnology.com");
          }}
        >
          <i className="icon fas fa-envelope"></i>
          <br />
          <label className="value">info@alwaantechnology.com</label>
          <br />
          <label className="key">Our E-Mail</label>
        </div>
        <div
          className="item"
          onClick={() => {
            onVisitLink("tel:+:918793435363");
          }}
        >
          <i className="icon fas fa-phone"></i>
          <br />
          <label className="value">+91 8793 435363</label>
          <br />
          <label className="key">Our Phone</label>
        </div>
      </div>
      <div className="copyright">
        Copyright © {new Date().getFullYear()} Alwaan Technology Pvt Ltd. <br />
        <br /> All Rights Reserved.
      </div>
      <div className="social-block">
        <i
          className="icon facebook fab fa-facebook-f"
          onClick={() => {
            onVisitLink(
              "https://www.facebook.com/people/Alwaan-Technology-Pvt-Ltd/100089090486919"
            );
          }}
        ></i>
        <i
          className="icon linkedin fab fa-linkedin-in"
          onClick={() => {
            onVisitLink(
              "https://www.linkedin.com/in/alwaan-technology-413ba4240"
            );
          }}
        ></i>
        <i
          className="icon instagram fab fa-instagram"
          onClick={() => {
            onVisitLink("https://www.instagram.com/alwaan.technology/");
          }}
        ></i>
        <i
          className="icon whatsapp fab fa-whatsapp"
          onClick={() => {
            window.open("https://wa.me/+918793435363");
          }}
        ></i>
      </div>
      <div className="policies-block">
        <ul>
          <li>
            <Link to={'/privacy-policy'} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
          </li>
          <li>
            <Link to={'/terms-and-conditions'} target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>
          </li>
          <li>
            <Link to={'/cancellation-and-refund-policy'} target="_blank" rel="noopener noreferrer">Cancellation / Refund Policy</Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
