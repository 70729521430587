import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import "./style.responsive.scss";
import Testimonials from "../../assets/Testimonials";

export default function TestimonialCarousel() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoPlay: true,
    autoplaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section id="testimonial">
        <div className="heading1">Couple of Words From Our Clients</div>
        <Slider {...settings}>
          {Testimonials.map((testimonial, i) => {
            return (
              <div key={i} className="item">
                <div className="block">
                  <div className="testimonial-item">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img className="avatar" src={testimonial.image} alt="Testimonial Avatar" />
                    </div>
                    <h1 className="title">{testimonial.title}</h1>
                    <p className="quote-text">{testimonial.text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
    </>
  );
}
