import React from "react";
import "./style.scss";

const TermsCondition = () => {
  return (
    <>
      <div id="policy-page">
        <h1>Terms and Conditions</h1>
        <ul>
          <li>
            <strong>Introduction:</strong> These terms and conditions govern
            your use of our website and services. By using our website and
            services, you accept these terms and conditions in full. If you
            disagree with these terms and conditions or any part of these terms
            and conditions, you must not use our website or services.
          </li>
          <li>
            <strong>Intellectual property rights:</strong> Unless otherwise
            stated, we or our licensors own the intellectual property rights in
            the website and material on the website. Subject to the license
            below, all these intellectual property rights are reserved.
          </li>
          <li>
            <strong>License to use website:</strong> You may view, download for
            caching purposes only, and print pages from the website for your own
            personal use, subject to the restrictions set out in these terms and
            conditions.
          </li>
          <li>
            <strong>Acceptable use:</strong> You must not use our website or
            services in any way that causes, or may cause, damage to the website
            or impairment of the availability or accessibility of the website;
            or in any way which is unlawful, illegal, fraudulent or harmful, or
            in connection with any unlawful, illegal, fraudulent or harmful
            purpose or activity.
          </li>
          <li>
            <strong>User content:</strong> In these terms and conditions, “your
            user content” means material (including without limitation text,
            images, audio material, video material and audio-visual material)
            that you submit to our website, for whatever purpose.
          </li>
          <li>
            <strong>Indemnity:</strong> You agree to indemnify us, and our
            directors, officers, employees and agents, from and against any
            claims, actions or suits or proceedings, as well as any losses,
            liabilities, damages, costs or expenses (including reasonable legal
            fees) arising out of or in any way connected with your use of our
            website or services, your user content or your breach of these terms
            and conditions.
          </li>
          <li>
            <strong>Severability:</strong> If a provision of these terms and
            conditions is determined by any court or other competent authority
            to be unlawful and/or unenforceable, the other provisions will
            continue in effect. If any unlawful and/or unenforceable provision
            would be lawful or enforceable if part of it were deleted, that part
            will be deemed to be deleted, and the rest of the provision will
            continue in effect.
          </li>
          <li>
            <strong>Entire agreement:</strong> These terms and conditions,
            together with our privacy policy, constitute the entire agreement
            between you and us in relation to your use of this website and
            services, and supersede all previous agreements in respect of your
            use of this website and services.
          </li>
        </ul>
      </div>
    </>
  );
};

export default TermsCondition;
