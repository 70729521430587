import React from "react";
import "./style.scss";

export default function MobileSidebar(props) {
  const { onNavigateToSection, onToggleMobileMenu } = props;

  return (
    <>
      <div className="mobile-sidebar-block" onClick={onToggleMobileMenu}>
        <div
          className="sidebar"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <div className="logo-block">
              <img src="/media/images/company-logo-alt.png" alt="Company Logo"/>
            </div>
            <ul className="menu">
              <li
                onClick={() => {
                  onNavigateToSection("home");
                }}
              >
                Home
              </li>
              <li
                onClick={() => {
                  onNavigateToSection("about-us");
                }}
              >
                About Us
              </li>
              <li
                onClick={() => {
                  onNavigateToSection("services");
                }}
              >
                Services
              </li>
              <li
                onClick={() => {
                  onNavigateToSection("contact-us");
                }}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
