import alert from "./alert.service";

export const submitForm = (data) => {
  return new Promise((resolve, reject) => {
    fetch("https://alwaantechnology.com/contact/contact.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        alert.success(response.message);
        resolve(true);
      })
      .catch((error) => {
        alert(error.error.message);
        reject(false);
      });
  });
};
