import React, { useEffect, useState } from "react";
import "./style.scss";
import "./style.responsive.scss";
import Navbar from "../Navbar/Navbar";
import MobileSidebar from "../MobileSidebar/MobileSidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Layout() {
  const [isPageOnTop, setIsPageOnTop] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const onPageScroll = (e) => {
    if (window.scrollY === 0) {
      setIsPageOnTop(true);
    } else {
      setIsPageOnTop(false);
    }
  };

  const scrollToTop = () => {
    document.querySelector(".scroll-to-top-button").classList.add("disable");

    setTimeout(() => {
      document
        .querySelector(".scroll-to-top-button")
        .classList.remove("disable");
    }, 1000);

    // Smoothly scroll the window to the top
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onNavigateToSection = (section) => {
    document.querySelector(".mobile-sidebar-block").classList.remove("active");

    if (location.pathname === "/") {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(`/?section=${section}`);
    }
  };

  const onToggleMobileMenu = () => {
    document.querySelector(".mobile-sidebar-block").classList.toggle("active");
  };

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll);
  });

  return (
    <>
      <div className="page">
        <Navbar
          isPageOnTop={isPageOnTop}
          onNavigateToSection={onNavigateToSection}
          onToggleMobileMenu={onToggleMobileMenu}
        />

        <Outlet />

        <MobileSidebar
          onNavigateToSection={onNavigateToSection}
          onToggleMobileMenu={onToggleMobileMenu}
        />

        <button
          onClick={scrollToTop}
          className={`scroll-to-top-button ${isPageOnTop ? "hidden" : ""}`}
        >
          <span>
            <i className="fas fa-arrow-up"></i>
            <br />
            <span>Top</span>
          </span>
        </button>
      </div>
    </>
  );
}
