import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import "./style.responsive.scss";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import Footer from "./../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";
import { submitForm } from "../../shared/services/contactus.service";

const Overview = () => {
  const childRef = useRef(null);
  const [contactForm, setContactForm] = useState({});

  useEffect(() => {
    const element = document.getElementById("cb1");
    element.click();
  }, []);

  const showContactForm = () => {
    if (childRef.current) {
      childRef.current.onToggleForm();
    }
  };

  const onContactFormInputHandler = (event) => {
    const { name, value } = event.target;

    setContactForm((props) => ({
      ...props,
      [name]: value,
    }));
  };

  const onContactFormSubmit = (e) => {
    e.preventDefault();

    submitForm(contactForm)
      .then((response) => {
        setContactForm({});
      })
      .catch((error) => {});
  };

  return (
    <>
      <div id="overview-page">
        <div className="header">
          <img src="/media/images/company-logo.png" alt="Company Logo" />
        </div>
        <section id="home">
          <div className="block">
            <div>
              <h1>Your Vision, Our Code</h1>
              <h3>Responsive Design, Seamless Functionality</h3>
              <button onClick={showContactForm}>Book A Call</button>
            </div>
          </div>
          <div className="block">
            <img src="/media/images/3-3-1.webp" alt="" />
          </div>
        </section>
        <section id="about">
          <div className="block">
            <h2>Welcome to Alwaan Technology Pvt Ltd</h2>
            <p>
              At Alwaan Technology Pvt Ltd, we are passionate about turning
              digital dreams into reality. As a leading software development
              company, we specialize in crafting exceptional websites, mobile
              apps, and digital solutions that empower businesses to thrive in
              the digital age. With a relentless commitment to innovation,
              creativity, and client satisfaction, we go beyond the ordinary to
              deliver tailored solutions that make a meaningful impact.
            </p>
            <p>
              Our comprehensive suite of services includes website development,
              mobile app development, cutting-edge digital marketing strategies,
              and search engine optimization (SEO) expertise. Whether you're a
              startup with a bold vision or an established enterprise seeking to
              stay ahead in a competitive landscape, we're here to transform
              your digital potential into success.
            </p>
            <p>
              Discover what sets us apart and embark on a journey of digital
              excellence with Alwaan Technology Pvt Ltd. Explore our portfolio,
              meet our team of seasoned experts, and let's embark on a digital
              transformation together.
            </p>
            <h3>Welcome to the future of digital solutions.</h3>
          </div>
          <div className="block">
            <div className="contact-form-container">
              <h3 className="text-uppercase">Contact Us</h3>
              <form className="contact-form" onSubmit={onContactFormSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    autoComplete="off"
                    value={contactForm.name || ""}
                    onChange={onContactFormInputHandler}
                  />
                  <label htmlFor="name">Name</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    required
                    autoComplete="off"
                    value={contactForm.email || ""}
                    onChange={onContactFormInputHandler}
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-group">
                  <select
                    name="service"
                    id="service"
                    required
                    value={contactForm.service || ""}
                    onChange={onContactFormInputHandler}
                  >
                    <option value="">--Select Service--</option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="Mobile App Development">
                      Mobile App Development
                    </option>
                    <option value="Software Development">
                      Software Development
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="SEO">
                      Search Engine Optimization (SEO)
                    </option>
                  </select>
                  <label htmlFor="service">Service</label>
                </div>
                <div className="form-group">
                  <input
                    required
                    type="number"
                    name="contact_number"
                    id="contact_number"
                    value={contactForm.contact_number || ""}
                    onChange={onContactFormInputHandler}
                  />
                  <label htmlFor="contact_number">Contact Number</label>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    required
                    value={contactForm.message || ""}
                    onChange={onContactFormInputHandler}
                  ></textarea>
                  <label htmlFor="message">Message</label>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </section>
        <section id="services">
          <div className="block">
            <img src="/media/images/3-3-2.png" alt="" />
          </div>
          <div className="block">
            <nav className="accordion arrows">
              <header className="box">
                <label htmlFor="acc-close" className="box-title">
                  Our Services
                </label>
              </header>
              <input type="radio" name="accordion" id="cb1" />
              <section className="box">
                <label className="box-title" htmlFor="cb1">
                  Web Development
                </label>
                <label className="box-close" htmlFor="acc-close"></label>
                <div className="box-content">
                  Our web development service offers custom website solutions
                  tailored to your needs. Whether you need a simple website or a
                  complex web application, our team of experts will work closely
                  with you to create a digital presence that reflects your brand
                  and engages your audience. We use the latest technologies to
                  ensure your website is responsive, secure, and optimized for
                  search engines.
                </div>
              </section>
              <input type="radio" name="accordion" id="cb2" />
              <section className="box">
                <label className="box-title" htmlFor="cb2">
                  Mobile App Development
                </label>
                <label className="box-close" htmlFor="acc-close"></label>
                <div className="box-content">
                  We specialize in mobile app development for both Android and
                  iOS platforms. Our mobile apps are designed to provide
                  seamless user experiences, with a focus on performance,
                  usability, and scalability. Whether you have a unique app idea
                  or need to extend your web presence to mobile, our team can
                  turn your vision into a feature-rich, user-friendly app.
                </div>
              </section>
              <input type="radio" name="accordion" id="cb3" />
              <section className="box">
                <label className="box-title" htmlFor="cb3">
                  Software Development
                </label>
                <label className="box-close" htmlFor="acc-close"></label>
                <div className="box-content">
                  Our software development expertise covers a wide range of
                  applications and solutions. From enterprise software to custom
                  desktop applications, we deliver high-quality software
                  products that streamline your business operations. Our
                  development process includes thorough testing and quality
                  assurance to ensure reliable, bug-free software that meets
                  your specific requirements.
                </div>
              </section>
              <input type="radio" name="accordion" id="cb4" />
              <section className="box">
                <label className="box-title" htmlFor="cb4">
                  Digital Marketing
                </label>
                <label className="box-close" htmlFor="acc-close"></label>
                <div className="box-content">
                  Our digital marketing strategies are designed to help your
                  business thrive in the online world. We offer a comprehensive
                  suite of digital marketing services, including search engine
                  optimization (SEO), pay-per-click (PPC) advertising, social
                  media marketing, email marketing, and content marketing. Our
                  goal is to increase your online visibility, drive targeted
                  traffic, and generate leads or sales for your business.
                </div>
              </section>
              <input type="radio" name="accordion" id="cb5" />
              <section className="box">
                <label className="box-title" htmlFor="cb5">
                  Search Engine Optimization (SEO)
                </label>
                <label className="box-close" htmlFor="acc-close"></label>
                <div className="box-content">
                  Our SEO services aim to boost your website's visibility and
                  rankings in search engines. We use proven SEO strategies and
                  best practices to optimize your website's content, meta tags,
                  and technical elements. Our team conducts keyword research,
                  competitor analysis, and ongoing optimization to ensure your
                  website ranks higher in search results, driving organic
                  traffic and potential customers to your site.
                </div>
              </section>
              {/* <input type="radio" name="accordion" id="acc-close" /> */}
            </nav>
          </div>
        </section>
        <section id="technology">
          <h2 className="heading">Technology Stack</h2>
          <div className="list">
            <div className="item">
              <div className="title">
                <h3>Frontend</h3>
              </div>
              <div className="box">
                <div className="image">
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Angular.webp"
                      alt="Angular"
                    />
                    <p>Angular</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/React.webp"
                      alt="React"
                    />
                    <p>React</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/JavaScript.webp"
                      alt="JavaScript"
                    />
                    <p>JavaScript</p>
                  </div>
                  <div className="img-container">
                    <img src="/media/images/technology/HTML.webp" alt="HTML" />
                    <p>HTML</p>
                  </div>
                  <div className="img-container">
                    <img src="/media/images/technology/css.webp" alt="CSS" />
                    <p>CSS</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Bootstrap.webp"
                      alt="Bootstrap"
                    />
                    <p>Bootstrap</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="title">
                <h3>Backend</h3>
              </div>
              <div className="box">
                <div className="image">
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Node_0.webp"
                      alt="Node"
                    />
                    <p>Node</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Java Spring.webp"
                      alt="Java Spring"
                    />
                    <p>Java Spring</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/ASP. Net.webp"
                      alt="ASP. Net"
                    />
                    <p>ASP. Net</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Laravel-1.webp"
                      alt="Laravel"
                    />
                    <p>Laravel</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Django_0.webp"
                      alt="Django"
                    />
                    <p>Django</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/codeigniter.webp"
                      alt="Codeigniter"
                    />
                    <p>Codeigniter</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="title">
                <h3>Database</h3>
              </div>
              <div className="box">
                <div className="image">
                  <div className="img-container">
                    <img
                      src="/media/images/technology/MySQL.webp"
                      alt="MySQL"
                    />
                    <p>MySQL</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/MS SQL.webp"
                      alt="MS SQL"
                    />
                    <p>MS SQL</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/MongoDB.webp"
                      alt="MongoDB"
                    />
                    <p>MongoDB</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Redis.webp"
                      alt="Redis"
                    />
                    <p>Redis</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Elastic Search.webp"
                      alt="Elastic Search"
                    />
                    <p>Elastic Search</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/GraphQL.webp"
                      alt="GraphQL"
                    />
                    <p>GraphQL</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="title">
                <h3>Mobile</h3>
              </div>
              <div className="box">
                <div className="image">
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Android.webp"
                      alt="Android"
                    />
                    <p>Android</p>
                  </div>
                  <div className="img-container">
                    <img src="/media/images/technology/iOS.webp" alt="iOS" />
                    <p>iOS</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Flutter.webp"
                      alt="Flutter"
                    />
                    <p>Flutter</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/React.webp"
                      alt="React Native"
                    />
                    <p>React Native</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/Ionic.webp"
                      alt="Ionic"
                    />
                    <p>Ionic</p>
                  </div>
                  <div className="img-container">
                    <img
                      src="/media/images/technology/NativeScript.webp"
                      alt="NativeScript"
                    />
                    <p>NativeScript</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TestimonialCarousel />
        <Footer />
      </div>
      <ContactForm ref={childRef} />
    </>
  );
};

export default Overview;
