import React, { useEffect, useState } from "react";
import "./style.scss";
import "./style.responsive.scss";
import { submitForm } from "../../shared/services/contactus.service";
import TestimonialCarousel from "./../../components/TestimonialCarousel/TestimonialCarousel";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

export default function Home() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactForm, setContactForm] = useState({});
  const navigate = useNavigate();

  const onNavigateToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onContactFormInputHandler = (event) => {
    const { name, value } = event.target;

    setContactForm((props) => ({
      ...props,
      [name]: value,
    }));
  };

  const onContactFormSubmit = (e) => {
    e.preventDefault();

    setIsSubmit(true);

    submitForm(contactForm)
      .then((response) => {
        setIsSubmit(false);
        setContactForm({});
        // Trigger the Google Analytics conversion event
        window.gtag("event", "conversion", {
          send_to: "AW-11328194697/bCAlCOzym-AYEImR2pkq",
          value: 1.0,
          currency: "INR",
        });
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const sectionToScroll = queryParams.get("section");

    window.scrollTo({ top: 0 });

    if (sectionToScroll) {
      const element = document.getElementById(sectionToScroll);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  }, []);

  return (
    <div id="home-page">
      <section id="home" style={{ paddingTop: "95px" }}>
        <div className="star-block w-100">
          <div className="position-absolute">
            <div style={{ "--i": "11" }} className="stars"></div>
            <div style={{ "--i": "12" }} className="stars"></div>
            <div style={{ "--i": "24" }} className="stars"></div>
            <div style={{ "--i": "10" }} className="stars"></div>
            <div style={{ "--i": "14" }} className="stars"></div>
            <div style={{ "--i": "23" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "16" }} className="stars"></div>
            <div style={{ "--i": "19" }} className="stars"></div>
            <div style={{ "--i": "20" }} className="stars"></div>
            <div style={{ "--i": "22" }} className="stars"></div>
            <div style={{ "--i": "25" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "11" }} className="stars"></div>
            <div style={{ "--i": "12" }} className="stars"></div>
            <div style={{ "--i": "24" }} className="stars"></div>
            <div style={{ "--i": "10" }} className="stars"></div>
            <div style={{ "--i": "14" }} className="stars"></div>
            <div style={{ "--i": "23" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "16" }} className="stars"></div>
            <div style={{ "--i": "19" }} className="stars"></div>
            <div style={{ "--i": "20" }} className="stars"></div>
            <div style={{ "--i": "22" }} className="stars"></div>
            <div style={{ "--i": "25" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "11" }} className="stars"></div>
            <div style={{ "--i": "12" }} className="stars"></div>
            <div style={{ "--i": "24" }} className="stars"></div>
            <div style={{ "--i": "10" }} className="stars"></div>
            <div style={{ "--i": "14" }} className="stars"></div>
            <div style={{ "--i": "23" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "16" }} className="stars"></div>
            <div style={{ "--i": "19" }} className="stars"></div>
            <div style={{ "--i": "20" }} className="stars"></div>
            <div style={{ "--i": "22" }} className="stars"></div>
            <div style={{ "--i": "25" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "11" }} className="stars"></div>
            <div style={{ "--i": "12" }} className="stars"></div>
            <div style={{ "--i": "24" }} className="stars"></div>
            <div style={{ "--i": "10" }} className="stars"></div>
            <div style={{ "--i": "14" }} className="stars"></div>
            <div style={{ "--i": "23" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
            <div style={{ "--i": "16" }} className="stars"></div>
            <div style={{ "--i": "19" }} className="stars"></div>
            <div style={{ "--i": "20" }} className="stars"></div>
            <div style={{ "--i": "22" }} className="stars"></div>
            <div style={{ "--i": "25" }} className="stars"></div>
            <div style={{ "--i": "18" }} className="stars"></div>
          </div>
          <div className="home-block">
            <div className="home-para">
              <h1>
                We Develop <strong>IT-Solutions</strong> For <br />
                <strong>Enterprises</strong> To Enhance{" "}
                <strong>Business Growth</strong>
              </h1>
            </div>
            <div className="home-img">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: "80%" }}
                  src="/media/images/main.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-us">
        <div className="section-row">
          <div className="certificate-block">
            <div className="box">
              <div className="frame">
                <img
                  className="certificate"
                  src="/media/images/startup-india-certificate.jpeg"
                  alt="Startup India Certificate"
                />
                <img
                  className="boy"
                  src="/media/images/7-8-2.png"
                  alt="Cartoon Boy"
                />
              </div>
            </div>
          </div>
          <div className="about-block">
            <h1 className="title">About Us</h1>
            <p>
              We are a technology-driven firm expert in custom product
              engineering and enterprise application development help clients
              add value to their business. With a diverse team of product
              engineering experts and problem solvers, we transform ideas into
              successful business solutions. Based on our technology expertise,
              we have established ourselves as a one-stop platform that covers
              everything from web development to IT consulting and outsourced
              product development to data analysis, cloud, and mobility.
            </p>
          </div>
        </div>
      </section>

      <section id="solution">
        <h1 className="title">Our Featured Solutions</h1>
        <div className="container" style={{ marginTop: "40px" }}>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="solution-item">
                <img src="/media/images/1-2-5.png" alt="Departments" />
                <br />
                <h2 className="heading">Departments</h2>
                <p>
                  The company's technological systems, maintenance of its
                  digital and technological infrastructure and ensuring that all
                  systems function together effectively.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="solution-item">
                <img src="/media/images/2-1-5.png" alt="Industries" />
                <br />
                <h2 className="heading">Industries</h2>
                <p>
                  The main focus of our company is to help businesses and
                  organizations improve their operations by providing them with
                  the necessary technology tools and solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="solution-item">
                <img src="/media/images/3-1-5.png" alt="Technology" />
                <br />
                <h2 className="heading">Technology</h2>
                <p>
                  We deals with a wide range of technologies to provide various
                  services and comprehensive solutions to our clients.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-top-big"></div>
        <div className="circle-top-small"></div>
      </section>

      <section id="services">
        <h1 className="title">Our Services</h1>
        <div className="service-list">
          <div
            onClick={() => {
              navigate("/web-development-and-designing");
            }}
            className="service-card"
          >
            <div className="icon-block">
              <img
                src="/media/images/website-design-development-services.png"
                alt="Web Development & Designing"
                srcSet=""
              />
            </div>
            <div>
              <h2 className="heading">Web Development & Designing</h2>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/mobile-app-development");
            }}
            className="service-card"
          >
            <div className="icon-block">
              <img
                src="/media/images/mobile-app-development-services.png"
                alt="Mobile App Development"
                srcSet=""
              />
            </div>
            <div>
              <h2 className="heading">Mobile App Development</h2>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/software-development");
            }}
            className="service-card"
          >
            <div className="icon-block">
              <img
                src="/media/images/software-development-services.png"
                alt="Software Development"
                srcSet=""
              />
            </div>
            <div>
              <h2 className="heading">Software Development</h2>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/digital-marketing");
            }}
            className="service-card"
          >
            <div className="icon-block">
              <img
                src="/media/images/digital-marketing-services.png"
                alt="Digital Marketing"
                srcSet=""
              />
            </div>
            <div>
              <h2 className="heading">Digital Marketing</h2>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/search-engine-optimization");
            }}
            className="service-card"
          >
            <div className="icon-block">
              <img
                src="/media/images/seo-services.png"
                alt="Search Engine Optimization"
                srcSet=""
              />
            </div>
            <div>
              <h2 className="heading">Search Engine Optimization</h2>
            </div>
          </div>
        </div>
      </section>

      <div id="contact-banner">
        <h1>&lt;/&gt;&nbsp;We Carry More Than Just Good Coding Skills</h1>
        <h2>Let's Build Your Business!</h2>
        <button
          onClick={() => {
            onNavigateToSection("contact-us");
          }}
        >
          Contact Us
        </button>
      </div>

      <TestimonialCarousel />

      <section id="contact-us">
        <div style={{ position: "relative" }}>
          <div className="contact-block">
            <div className="form-block">
              <div className="form-left">
                <div>
                  <div className="text-center">
                    <img src="/media/images/download.png" alt="Message Logo" />
                  </div>
                  <p className="desktop">
                    If you have questions or just want to get in touch, use the
                    form beside. We look forward to hearing from you.
                  </p>
                  <p className="mobile">
                    If you have questions or just want to get in touch, use the
                    form below. We look forward to hearing from you.
                  </p>
                </div>
              </div>
              <form onSubmit={onContactFormSubmit} autoComplete="off">
                <h1 className="title">Contact Us</h1>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={contactForm.name || ""}
                    className="form-control"
                    onChange={onContactFormInputHandler}
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={contactForm.email || ""}
                    className="form-control"
                    onChange={onContactFormInputHandler}
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    type="number"
                    placeholder="Contact Number"
                    name="contact_number"
                    value={contactForm.contact_number || ""}
                    className="form-control"
                    onChange={onContactFormInputHandler}
                  />
                </div>
                <div className="form-group">
                  <select
                    required
                    name="service"
                    value={contactForm.service || ""}
                    className="form-control"
                    onChange={onContactFormInputHandler}
                  >
                    <option value="">-- Select Service --</option>
                    <option value="web-development">Web Development</option>
                    <option value="mobile-app-development">
                      Mobile App Development
                    </option>
                    <option value="software-development">
                      Software Development
                    </option>
                    <option value="digital-marketing">Digital Marketing</option>
                    <option value="seo">Search Engine Optimization</option>
                  </select>
                </div>
                <div className="form-group">
                  <textarea
                    required
                    placeholder="Message"
                    name="message"
                    value={contactForm.message || ""}
                    rows="5"
                    className="form-control"
                    onChange={onContactFormInputHandler}
                  ></textarea>
                </div>
                <div className="form-group text-center">
                  <button disabled={isSubmit} className="contact-form-btn">
                    {isSubmit ? (
                      <span className="loader-icon fas fa-pulse fa-spinner"></span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
