const Testimonials = [
  {
    image: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYAve76C9WgPgI1Cw8mB6ZieG4cBWfHvN90b24gKBqr1UVIbz8JdeW2gi5N-irwzqGBis&usqp=CAU`,
    title: `Dr. Shaila Bootwala - Principal, Abeda Inamdar Senior College`,
    text: `Alwaan Technology highly accomplished team who helped us in
        successful launch of our Web Application which was extremely
        vigorous and highly extensible. Their development team was
        amazing and has definitely helped us.`,
  },
  {
    image: `media/images/testimonial/AG_Creations.png`,
    title: `Atharva Ghadge, Founder - AG Creations`,
    text: `I really enjoyed working with Alwaan Technology. 
          I found the team to be super talented and patient during the design process. 
          I would highly recommend them for any mobile app project.`,
  },
];

export default Testimonials;
